import ConfiguratorDesign from "views/ConfiguratorDesign"
import ConfiguratorLayout from "components/Configurator/Layout"
import { PageProps } from "gatsby"
import React from "react"
import SEO from "components/seo"

type DataProps = {}

const ConfiguratorDesignPage: React.FC<PageProps<DataProps>> = () => (
  <ConfiguratorLayout activePageId="design">
    <SEO title="Configurator Design" />
    <ConfiguratorDesign />
  </ConfiguratorLayout>
)

export default ConfiguratorDesignPage
